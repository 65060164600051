:root {
  --fontRegular: 14px;
  --radius: 0.25rem;
  --mainPadding: 3rem;
  --btn-padding: 0.5rem;
  --mainColor: #38a6ad;
  --mainColoDark: #948080;
  --secondaryColor: #818181;
  --success: #69da6b;
  --incomplete: #d11606;
  --editorFontSize: 16px;
  --editorHeight: 33.5vh;
  --gridGap: 1.5rem;
  color: #d2eef0;
  margin: 0;
  padding: 0;
}
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.App {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 3rem;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fontRegular);

  background: linear-gradient(to right, var(--mainColor) 50%, #a3ab9c 50%);
}
.app-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
}
.app-container_results,
.app-container_play {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.show {
  display: flex;
}
.no-show {
  display: none;
}

.editor_title {
  background-color: #262729;
  color: white;
  align-self: flex-start;
  padding: var(--btn-padding);
  width: 100%;
  margin: 0;
  border-radius: var(--radius) var(--radius) 0 0;
}
.editor__emmet,
.editor__result-html,
.editor__expected-html {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 2rem;
}
.editor__emmet {
  height: 50%;
  max-height: 200px;
  position: relative;
}

.react-codemirror2 {
  width: 100%;
  font-size: var(--editorFontSize);
}
.CodeMirror {
  /* border-radius: var(--radius); */
  /* height: var(--editorHeight); */
}
div.CodeMirror-scroll > div.CodeMirror-gutters {
  background-color: var(--secondaryColor);
}
.CodeMirror-code > div > div > div {
  color: white;
}

.wrong:hover {
  animation: headShake 1s ease-in;
}
.btn-next.correct {
  background-color: var(--success);
  /* animation: beat 0.7s infinite alternate; */
  animation: pulse 2s infinite; /* referring directly to the animation's @keyframe declaration */
}

.editor__emmet .btn-next.disabled:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

/* animations */
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes beat {
  to {
    transform: scale(1.2);
  }
}

@media only screen and (max-width: 850px) {
  body {
    overflow: hidden;
  }
  .App {
    background: none;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    width: 107vw;
    height: 107vh;
  }
  .app-container {
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    background-color: var(--mainColor);
    overflow: scroll;

    height: unset;
  }
  .app-container_results,
  .app-container_play {
    width: 98vw;
    padding: 1rem;
  }

  .app-container_play {
    background-color: var(--mainColor);
  }
  .app-container_results {
    background-color: #a3ab9c;
  }
  .credit {
    top: 93vh;
  }
}
