.header_exercises {
  position: relative;
  background-color: var(--mainColoDark);
  border-radius: var(--radius);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 30px;
}

.header_exercises__nav-title {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 var(--gridGap) 0 var(--gridGap);
}
.header_exercises__nav-title:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.header_exercises__nav-title span {
  display: inline-block;
  width: 3ch;
}
